import React, {Component} from "react";
import {Grid, Header, Image} from "semantic-ui-react";
import AttachEmailForm from "../components/forms/attach-email-form";
import {connect} from "react-redux";
import {rejectIfAnyNull} from "../controllers/utilities/env-checks";
import {formatQuery} from "../controllers/utilities/string-utils";
import {navigate} from "../../.cache/gatsby-browser-entry";
import NavigationBar from "../components/forms/navigation-bar";

class AttachEmail extends Component {
    constructor(props, context) {
        super(props, context);
        rejectIfAnyNull(this.props.session);
    }

    render() {
        let {message} = formatQuery(this.props.location.search);
        message = decodeURI(message);
        const mode = detectOrient();
        if (typeof window !== `undefined`) {
            var docEl = document.documentElement;
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if (mode === "PORTRAIT"){
                docEl.style.fontSize = 14 * (clientWidth / 450) + 'px';
            }else{
                // docEl.style.fontSize = 14 * (clientWidth / 900) + 'px';
            }              
        }
        return (
            <div className="whiteDiv" textAlign='center' style={{
                width:"100%",
                minHeight: "100vh",
                textAlign:"center",
                margin:"0 auto"
            }} >

<NavigationBar mode={mode} platform={"h5"} showLogo={1} showTitle={3} onBack={() => {
                                navigate(`/sdk-callback`);
                            }}/>

                <div style={{maxWidth: `${(mode === "PORTRAIT")?"450px":"360px"}`, height:"100vh", paddingTop:"80px", margin:"0 auto"}}>
                    <div style={{padding:"5vh"}}>
                    <AttachEmailForm session={this.props.session} msg={message}/>
                    </div>
                </div>
            </div>            
        );
    }
}

function mapStateToProps(state) {
    return {
        session: state.session
    };
}

export default connect(mapStateToProps)(AttachEmail);

function detectOrient() {
    if (typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    } else {
        return "PORTRAIT";
    }
}