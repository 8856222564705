import React, { Component } from "react";
import { Button, Card, CardContent, CardHeader, Container, Form, Message, Segment } from "semantic-ui-react";
import { Link } from "gatsby";
import { EmailField } from "./form-fields";
import KaisakuUserApi from "../../controllers/kaisaku-user-api";
import withTrans from "../../i18n/withTrans";
import pwdbox from "../../images/login/pwdbox.png";
import email from "../../images/login/email.png";
import pwdhint from "../../images/login/pwdhint.png";
import sendemail from "../../images/login/sendemail.png";
import modifypwd from "../../images/login/modifypwd.png";


class AttachEmailForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            email: "",
            errorMsgs: [],
            alertMsgs: "",
            loading: false,
            hasEmail: false,
            success: false
        };
    }

    async componentDidMount() {
        this.setState({
            loading: true
        });
        const res = await new KaisakuUserApi(this.props.session).getProfile();
        console.log(res);
        if (res.status === 200) {
            this.profile = res.data;
            this.setState({
                loading: false,
                hasEmail: !!this.profile.email
            });
        } else {
            console.error("Error getting user profile!");
        }
    }

    async onSubmit() {
        this.setState({
            loading: true,
            success: false,
            errorMsgs: []
        });
        const res = await new KaisakuUserApi(this.props.session).attachEmail(this.state.email);
        this.processResult(res);
    }


    processResult(result) {
        if (result.success) {
            this.setState({
                loading: false,
                success: true
            });
        } else {
            this.setState({
                loading: false,
                success: result.success,
                errorMsgs: result.errorMsgs.map(this.props.t)
            });
        }
    }

    render() {
        let msg = this.props.msg;
        let alert = false;
        console.log("222");
        console.log(msg);
        if ((msg) && (msg !== undefined) && (msg !== "undefined")) {
            alert = true;
        }
        console.log(alert);
        return (
            <Container fluid>
                <div style={{
                    padding: "20px",
                    backgroundImage: `url(${pwdbox})`, backgroundSize: '100% 100%'
                }}>

                    <Card className="whiteDiv" fluid style={{
                        boxShadow: '0 1px 1px 0 #FFF, 0 0 0 1px #FFF'
                    }}><Form size='large' loading={this.state.loading} success={this.state.success}
                        msg={msg}
                        error={this.state.errorMsgs.length > 0}>
                            <CardContent>
                                {(this.state.errorMsgs.length > 0) && (<div style={{ color: "#2EB414", paddingTop: "10px" }}>郵箱已綁定</div>)}
                                {(this.state.success) && (<div style={{color:"#2EB414", paddingTop: "10px"}}>郵箱已綁定</div>)}
                                <div style={{
                                    
                                    padding: "10px", 
                                    height: "4rem",
                                    border: "0px",
                                    backgroundImage: `url(${email})`, backgroundSize: '100% 100%'
                                }}>
                                    <EmailField
                                        style={{
                                            width: "80%", height: "2.8rem",
                                            marginTop: "-3px",
                                            marginLeft: "10%",
                                            backgroundColor: "#FFF", color: "#333"
                                        }}
                                        onChange={(e, data) => this.setState({ email: data.value })} />
                                </div>

                                {(alert) && (<Message
                                    success={!alert}
                                    content={msg}
                                />)}
                                 {(!this.state.success) && (<Button disabled={this.state.success}
                                    fluid size='large' style={{
                                        height: "4.2rem",
                                        background: "transparent",
                                        backgroundImage: `url(${sendemail})`, backgroundSize: '100% 100%'
                                    }}
                                    onClick={this.onSubmit.bind(this)}>
                                    &nbsp;
                                    {/* {this.state.success ? "驗證郵件已發出，請檢查你的郵件箱" : "綁定郵箱地址"} */}
                                </Button>)}
                            </CardContent>
                        </Form>
                    </Card>
                    {this.state.hasEmail ?
                        <Message>
                            已綁定郵箱，如要更改郵箱地址可重新綁定
                        </Message>
                        :
                        null
                    }
                </div>

            </Container>
        );
    }
}

export default withTrans(AttachEmailForm);